<template>
	<modal class="NoxModalPackageExtend" name="NoxModalPackageExtend" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title" v-html="$store.getters.getLanguageText('5.5', 0)"></div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxBuyStep == 1">
						<p><span v-html="$store.getters.getLanguageText('5.16', 7, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance1, 2) })"></span><br><span v-html="$store.getters.getLanguageText('5.16', 8, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance3, 2) })"></span></p>
						<p v-html="$store.getters.getLanguageText('5.5', 1)"></p>
						<p class="nox_p_radios">
							<label class="nox_radio" :for="'nox_radio_subscription_' + index" v-for="(value, index) in noxSubscriptions" :key="index">
								<input type="radio" :id="'nox_radio_subscription_' + index" name="nox_radio_subscription" :value="value" v-model.number="noxSubscription">
								<span class="nox_radio_mark"></span>
								<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.5', 2, { options: [(value == 1 ? 0 : 2)], months: value, cost: parseFloat(value == 1 ? noxTotalSubscriptionCostM1 : (value == 6 ? noxTotalSubscriptionCostM6 : noxTotalSubscriptionCostM12)).toFixed(2) })"></span></span>
							</label>
						</p>
						<p v-if="((noxSubscription == 1 && !noxIsBuySubscriptionM1) || (noxSubscription == 6 && !noxIsBuySubscriptionM6) || (noxSubscription == 12 && !noxIsBuySubscriptionM12))"><span class="red" v-html="$store.getters.getLanguageText('5.16', 12, { amount: parseFloat((noxSubscription == 1 ? noxTotalSubscriptionCostM1 : (noxSubscription == 6 ? noxTotalSubscriptionCostM6 : noxTotalSubscriptionCostM12)) - noxBalance).toFixed(2) })"></span></p>
					</div>
					<div v-else-if="noxBuyStep == 2" v-html="$store.getters.getLanguageText('5.5', 3, { amount: parseFloat((noxSubscription == 1 ? noxTotalSubscriptionCostM1 : (noxSubscription == 6 ? noxTotalSubscriptionCostM6 : noxTotalSubscriptionCostM12))).toFixed(2) })"></div>
					<div class="center" v-else-if="!noxBuyStep" v-html="$store.getters.getLanguageText('5.5', 4, { options: [(noxSubscription == 1 ? 0 : 2)], months: noxSubscription })"></div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="noxBuyStep = 1" v-if="noxBuyStep == 2" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
					<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="noxBuyStep = 2" v-if="noxBuyStep == 1 && ((noxSubscription == 1 && noxIsBuySubscriptionM1) || (noxSubscription == 6 && noxIsBuySubscriptionM6) || (noxSubscription == 12 && noxIsBuySubscriptionM12))" v-html="$store.getters.getLanguageText('1.1', 49)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxBuyStep == 2 && ((noxSubscription == 1 && noxIsBuySubscriptionM1) || (noxSubscription == 6 && noxIsBuySubscriptionM6) || (noxSubscription == 12 && noxIsBuySubscriptionM12))" v-html="$store.getters.getLanguageText('1.1', 36)"></button>
					<button type="button" class="nox_button common green" @click="close();$modal.show('NoxModalBalance', { type: 'addMainBalance' })" v-else-if="!((noxSubscription == 1 && noxIsBuySubscriptionM1) || (noxSubscription == 6 && noxIsBuySubscriptionM6) || (noxSubscription == 12 && noxIsBuySubscriptionM12))" v-html="$store.getters.getLanguageText('1.1', 100)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxBuyStep: 0,
			noxPackage: 0,
			noxBalance: 0,
			noxBalance1: 0,
			noxBalance2: 0,
			noxBalance3: 0,
			noxSystemVat: 0,
			noxSubscription: 0,
			noxSubscriptions: [],
			noxSubscriptionPeriodM1: 0,
			noxSubscriptionPeriodM6: 0,
			noxSubscriptionPeriodM12: 0,
			noxSubscriptionCostM1: 0,
			noxSubscriptionCostM6: 0,
			noxSubscriptionCostM12: 0,
			noxTotalSubscriptionCostM1: 0,
			noxTotalSubscriptionCostM6: 0,
			noxTotalSubscriptionCostM12: 0,
			noxIsBuySubscriptionM1: true,
			noxIsBuySubscriptionM6: true,
			noxIsBuySubscriptionM12: true,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function() {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxBuyStep = 1;
				this.noxPackage = Number(this.$store.state.noxAccountData.package);
				this.noxBalance1 = Number(this.$store.state.noxAccountData.balance_nox);
				this.noxBalance2 = Number(this.$store.state.noxAccountData.balance_nox_zp);
				this.noxBalance3 = Number(this.$store.state.noxAccountData.balance_of_bonuses);
				this.noxBalance = Math.round((this.noxBalance1 + this.noxBalance3) * 100) / 100;
				this.noxSystemVat = Number(this.$store.state.noxSystemSettings.vat_value);
				this.noxSubscription = 6;
				this.noxSubscriptions = [1,6,12];
				this.noxSubscriptionPeriodM1 = Number(this.$store.state.noxSystemSettings['subscription_period_m1']);
				this.noxSubscriptionPeriodM6 = Number(this.$store.state.noxSystemSettings['subscription_period_m6']);
				this.noxSubscriptionPeriodM12 = Number(this.$store.state.noxSystemSettings['subscription_period_m12']);
				this.noxSubscriptionCostM1 = Number(this.$store.state.noxSystemSettings['subscription_cost_x' + this.noxPackage + '_m1']);
				this.noxSubscriptionCostM6 = Number(this.$store.state.noxSystemSettings['subscription_cost_x' + this.noxPackage + '_m6']);
				this.noxSubscriptionCostM12 = Number(this.$store.state.noxSystemSettings['subscription_cost_x' + this.noxPackage + '_m12']);
				this.noxTotalSubscriptionCostM1 = this.noxSubscriptionCostM1 * (1 + this.noxSystemVat / 100);
				this.noxTotalSubscriptionCostM6 = this.noxSubscriptionCostM6 * (1 + this.noxSystemVat / 100);
				this.noxTotalSubscriptionCostM12 = this.noxSubscriptionCostM12 * (1 + this.noxSystemVat / 100);
				this.noxTotalSubscriptionCostM1 = Math.round(this.noxTotalSubscriptionCostM1 * 100) / 100;
				this.noxTotalSubscriptionCostM6 = Math.round(this.noxTotalSubscriptionCostM6 * 100) / 100;
				this.noxTotalSubscriptionCostM12 = Math.round(this.noxTotalSubscriptionCostM12 * 100) / 100;
				this.noxIsBuySubscriptionM1 = this.noxBalance >= this.noxTotalSubscriptionCostM1 ? true : false;
				this.noxIsBuySubscriptionM6 = this.noxBalance >= this.noxTotalSubscriptionCostM6 ? true : false;
				this.noxIsBuySubscriptionM12 = this.noxBalance >= this.noxTotalSubscriptionCostM12 ? true : false;
				this.noxIsLoading = false;
				this.noxIsError = false;
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 3); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 4); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 6); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 8); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.3', 10); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {
					if ((_this.noxSubscription == 1 && _this.noxIsBuySubscriptionM1) || (_this.noxSubscription == 6 && _this.noxIsBuySubscriptionM6) || (_this.noxSubscription == 12 && _this.noxIsBuySubscriptionM12)) {

						var config = {
							url: '/v2/account/users/package_at/buy',
							data: { subscription: _this.noxSubscription },
							method: 'patch', baseURL: _this.$store.state.noxApiBaseURL,
							headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
						};

						_this.resetAlerts();
						_this.noxIsLoading = true;
						_this.noxIsError = false;

						axios(config).then(function(data) {
							if (data.status == 200) {
								_this.$parent.$parent.$parent.initAccountData(data.data); _this.noxBuyStep = 0;
								if (_this.$parent.noxIsWidget1 !== undefined) { _this.$parent.noxIsWidget1 = false; }
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'SUBSCRIPTION_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'SUBSCRIPTION_NOT_VALID') { _this.noxAlert = _this.getError(2); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 409) {
									if      (data.response.data.error == 'NOT_ENOUGH_MONEY') { _this.noxAlert = _this.getError(3); }
									else if (data.response.data.error == 'NEGATIVE_BALANCE') { _this.noxAlert = _this.getError(4); }
									else if (data.response.data.error == 'NO_PURCHASED_PACKAGE') { _this.noxAlert = _this.getError(5); }
									else if (data.response.data.error == 'SPONSOR_ID_IS_EMPTY') { _this.noxAlert = _this.getError(6); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
					else {
						_this.$parent.$parent.$parent.goToTop(true); _this.$router.push({ path: '/account/balance' });
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalPackageExtend');
			}
		}
	}
</script>
